import { Tooltip } from '@canvaza/serval-ui';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import React from 'react';

import { DotIcon } from '@/shared/utils/svg';

type AttributeItemProps = {
  children: ReactNode;
  helperInfo?: string;
  isIntegration?: boolean;
  isPricingPage?: boolean;
  isCurrentWorkspacePlan?: boolean;
};
export default function AttributeItem({
  children,
  helperInfo,
  isIntegration,
  isPricingPage,
  isCurrentWorkspacePlan,
}: AttributeItemProps) {
  return (
    <div className="flex items-center gap-1">
      <div
        className={clsx('w-6', {
          flex: !isIntegration,
          hidden: isIntegration,
        })}
      >
        <DotIcon
          className={clsx('h-6 w-6', {
            'fill-primary-light': isPricingPage,
            'fill-dark-light dark:fill-dark-lighter': isCurrentWorkspacePlan,
            'fill-primary dark:fill-primary-light':
              !isPricingPage && !isCurrentWorkspacePlan,
          })}
        />
      </div>
      {children}
      {helperInfo && (
        <Tooltip placement="top" content={helperInfo}>
          <InformationCircleIcon
            className={clsx('h-6 w-5 flex-none', {
              'text-primary-light': isPricingPage,
              'text-dark-lighter': isCurrentWorkspacePlan,
              'text-primary dark:text-primary-light':
                !isPricingPage && !isCurrentWorkspacePlan,
            })}
          />
        </Tooltip>
      )}
    </div>
  );
}
