import { LockClosedIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React from 'react';

import SelectInput from '@/common/component/forms/SelectInput';
import type { IBillingScheme } from '@/shared/firebase';

type PlanTitleProps = {
  name: string;
  isForYou?: boolean;
  billingScheme?: IBillingScheme;
  isPricePage?: boolean;
  isLanding?: boolean;
  isCurrentWorkspacePlan?: boolean;
  onBillingSchemeChange?: (billingScheme: IBillingScheme) => void;
};
export default function PlanTitle({
  name,
  isForYou,
  isLanding,
  isCurrentWorkspacePlan,
  billingScheme,
  onBillingSchemeChange,
  isPricePage,
}: PlanTitleProps) {
  return (
    <div className="flex w-full items-center justify-between gap-3">
      <div className="flex items-center justify-between gap-3">
        <div className="flex items-center gap-2">
          <h3
            className={clsx('text-sm font-semibold uppercase leading-8', {
              'text-primary-light': isPricePage,
              'text-dark-lighter': isCurrentWorkspacePlan,
              'text-primary dark:text-primary-light':
                !isPricePage && !isCurrentWorkspacePlan,
            })}
          >
            {name}
          </h3>
          {isForYou && (
            <LockClosedIcon className="w-4 fill-success dark:bg-success-light" />
          )}
          {isForYou && (
            <SelectInput
              className={clsx('w-24 border-primary-light/25', {
                'text-dark-light': isPricePage || isCurrentWorkspacePlan,
                'text-dark-700': isLanding,
                'text-dark-700 dark:text-dark-light':
                  !isPricePage && !isLanding,
              })}
              size="small"
              onChange={(e) => {
                if (onBillingSchemeChange && isForYou) {
                  onBillingSchemeChange(
                    e.currentTarget.value as IBillingScheme
                  );
                }
              }}
              value={billingScheme}
              options={[
                { label: 'Per unit', value: 'per_unit' },
                { label: 'Tier', value: 'tiered' },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
}
