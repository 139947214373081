import clsx from 'clsx';
import React from 'react';

type PlanDescriptionProps = {
  description: string;
  isPricePage?: boolean;
  isLanding?: boolean;
};
export default function PlanDescription({
  description,
  isPricePage,
  isLanding,
}: PlanDescriptionProps) {
  return (
    <p
      className={clsx('text-sm', {
        'text-dark-light': isPricePage,
        'text-dark-700': isLanding,
        'text-dark-600 dark:text-dark-light': !isPricePage && !isLanding,
      })}
    >
      {description}
    </p>
  );
}
