import { Button, Modal, useToast } from '@canvaza/serval-ui';
import { useState } from 'react';

import TieredPricePreview from '@/app/(protected)/(admin)/(plan)/plans/price/TieredPricePreview';
import { getCurrencySymbol } from '@/shared/config/currency.config';
import { Endpoints } from '@/shared/config/endpoint.config';
import { UPGRADE } from '@/shared/config/route-links.config';
import type { IBillingScheme, IPlan, IPlanPrice } from '@/shared/firebase';
import type {
  ISubscription,
  ISubscriptionUpgrade,
} from '@/shared/firebase/models/subscription.interface';
import { usePostAPI } from '@/shared/hooks/fetch.hooks';

import type { ISelectInterval } from '../../..';

export type PlanType = 'free' | 'standard' | 'custom' | 'custom_price';

type ActionProps = {
  type: PlanType;
  plan?: IPlan;
  workspaceSubscription?: ISubscription;
  priceSubscription?: boolean;
  interval?: ISelectInterval;
  billingScheme?: IBillingScheme;
  intervalPrice?: IPlanPrice;
  openContactSalesModal?: () => void;
};
export default function Action({
  type,
  plan,
  interval,
  intervalPrice,
  billingScheme,
  workspaceSubscription,
  priceSubscription,
  openContactSalesModal,
}: ActionProps) {
  const [showUpgradeConfirmationModal, setShowUpgradeConfirmationModal] =
    useState(false);
  const toast = useToast();
  const { post: upgradeSubscriptions, state: upgradeSubscriptionsState } =
    usePostAPI<ISubscription, ISubscriptionUpgrade>();

  const showConfirmationModal = () => setShowUpgradeConfirmationModal(true);
  const closeConfirmationModal = () => setShowUpgradeConfirmationModal(false);
  const handleSubscribeRoute = () => {
    if (!plan) return;
    let routeTo = `${UPGRADE.url}/${plan.id}?interval=${interval}`;
    if (billingScheme) routeTo += `&billingScheme=${billingScheme}`;
    window.location.href = routeTo;
  };

  const handleUpgradeSubscription = async () => {
    if (!workspaceSubscription || !plan || !intervalPrice) return;
    await upgradeSubscriptions({
      apiUrl: Endpoints.payment.appEngines.upgradeSubscription(),
      body: {
        planId: plan.id,
        subscriptionId: workspaceSubscription.id,
        priceId: intervalPrice.id,
      },
    });
    toast?.pushSuccess('Successfully upgraded.');
    window.location.href = UPGRADE.url;
  };

  const handleUpgradeButtonAction = () => {
    if (workspaceSubscription) showConfirmationModal();
    else handleSubscribeRoute();
  };

  return (
    <>
      {type === 'custom' && (
        <div className="flex w-full justify-center">
          <Button onClick={openContactSalesModal} variant="outlined" fullWidth>
            Talk to Sales
          </Button>
        </div>
      )}

      {(type === 'standard' || type === 'custom_price') && (
        <div className="w-full">
          {priceSubscription ? (
            <span className="rounded-full bg-dark-light/10 px-4 py-2 text-center text-sm font-semibold text-dark-lighter">
              {workspaceSubscription && workspaceSubscription?.workspace.name}
              {!workspaceSubscription && 'Your Current Plan'}
            </span>
          ) : (
            <Button
              onClick={handleUpgradeButtonAction}
              variant="contained"
              fullWidth
            >
              Upgrade
            </Button>
          )}
        </div>
      )}

      <Modal
        open={showUpgradeConfirmationModal}
        title="Upgrade subscription"
        actions={
          <div className="flex items-center gap-2 p-2">
            <Button size="small" color="dark" onClick={closeConfirmationModal}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={upgradeSubscriptionsState.isLoading}
              isLoading={upgradeSubscriptionsState.isLoading}
              onClick={handleUpgradeSubscription}
            >
              Upgrade
            </Button>
          </div>
        }
      >
        {intervalPrice?.billingScheme === 'per_unit' && (
          <p className="p-4">
            {`Are you ready to upgrade to the ${
              plan?.name
            } Plan for ${getCurrencySymbol(intervalPrice?.currency ?? '')}${
              intervalPrice?.unitAmount
            } per ${interval}?`}
          </p>
        )}

        {intervalPrice?.billingScheme === 'tiered' && intervalPrice.tiers && (
          <div className="flex flex-col items-start gap-2 p-4">
            <span>{`Are you ready to upgrade to the ${plan?.name} Plan for:`}</span>
            <TieredPricePreview
              currency={intervalPrice.currency}
              tiers={intervalPrice.tiers}
            />
            <span>`per ${interval}?`</span>
          </div>
        )}
      </Modal>
    </>
  );
}
