import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React from 'react';

type IntegrationIconsProps = {
  hasIntegration?: boolean;
  isCustom?: boolean;
  name: string | null;
};

export default function IntegrationIcons({
  name,
  hasIntegration,
  isCustom,
}: IntegrationIconsProps) {
  return (
    <div
      className={clsx('items-center gap-3 sm:gap-2 md:gap-3', {
        'hidden lg:flex': isCustom,
        flex: !isCustom,
      })}
    >
      {hasIntegration ? (
        <CheckIcon className="w-4" />
      ) : (
        <XMarkIcon className="w-4 opacity-40" />
      )}
      <span
        className={clsx('text-sm', {
          'opacity-1': hasIntegration,
          'opacity-40': !hasIntegration,
        })}
      >
        {name}
      </span>
    </div>
  );
}
