import clsx from 'clsx';
import React from 'react';

type PlanContainerProps = {
  children: React.ReactNode;
  priceSubscription?: boolean;
  isSummary?: boolean;
  isCurrentWorkspacePlan?: boolean;
  isLanding?: boolean;
  isPricingPage?: boolean;
  isPopular?: boolean;
};
export default function PlanContainer({
  children,
  priceSubscription,
  isSummary,
  isCurrentWorkspacePlan,
  isLanding,
  isPricingPage,
  isPopular,
}: PlanContainerProps) {
  return (
    <div className="flex w-full">
      <div
        className={clsx(
          'relative h-full min-w-fit rounded-3xl px-6 py-2 xl:p-4',
          'flex w-full flex-col justify-center gap-3 p-4 transition duration-300',
          'rounded-s-3xl border-b-4 border-t-8 border-primary dark:border-primary-light',
          {
            'ring-1 ring-primary-light': priceSubscription,
            'ring-1 ring-dark-200/0 dark:ring-dark-500':
              !priceSubscription && !isSummary,
            'shadow-lg shadow-dark-100/40 hover:shadow-lg hover:shadow-primary-lighter/40 hover:ring-2 hover:ring-primary dark:hover:ring-primary-light':
              !isSummary,
            'shadow-lg': isSummary,
            'bg-primary': isCurrentWorkspacePlan,
            'bg-dark-300/5': !isCurrentWorkspacePlan && isPricingPage,
            'bg-dark-lighter/0': !isPricingPage,
            'ring-0 ring-dark-200/0': isLanding || isPricingPage,
          }
        )}
      >
        <div
          className={clsx(
            'absolute right-0 top-0 rounded-se-2xl bg-dark-lighter px-4 py-2',
            {
              flex: isCurrentWorkspacePlan,
              hidden: !isCurrentWorkspacePlan,
            }
          )}
        >
          <p className={clsx('text-sm font-medium text-dark-700')}>
            Current workspace plan
          </p>
        </div>
        {isLanding && isPopular && (
          <div className="absolute right-0 top-6 rounded-s-xl bg-gradient-to-r from-primary/80 to-primary-light p-2 xs:px-4 sm:px-6">
            <span className="text-xs font-medium text-dark-light xs:text-sm">
              Recommended
            </span>
          </div>
        )}
        <div className="flex h-full max-w-96 flex-col justify-between gap-6 p-4 pt-6 2xl:max-w-full">
          {children}
        </div>
      </div>
    </div>
  );
}
