import { Button } from '@canvaza/serval-ui';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import React, { useMemo } from 'react';

import type {
  IBillingScheme,
  IPlan,
} from '@/shared/firebase/models/stripe-payment.interface';
import type { ISubscription } from '@/shared/firebase/models/subscription.interface';
import { useAuth } from '@/shared/hooks/useAuth';
import useUserAbility from '@/shared/hooks/useUserAbility';

import type { ISelectInterval } from '../..';
import Attributes from './attributes';
import Action from './components/Action';
import PlanContainer from './components/PlanContainer';
import PlanDescription from './components/PlanDescription';
import PlanTitle from './components/PlanTitle';
import PricesPreview from './price-preview';

type UserCustomPlanProps = {
  isPricePage?: boolean;
  isLanding?: boolean;
  isPriceOverviewPage?: boolean;
  plan: IPlan;
  workspaceSubscription?: ISubscription;
  interval: ISelectInterval;
  isSummary?: boolean;
  billingScheme?: IBillingScheme;
  isWorkspaceSetting?: boolean;
  isCurrentWorkspacePlan?: boolean;
  onBillingSchemeChange?: (billingScheme: IBillingScheme) => void;
  onCancelSubscription?: () => void;
  onPauseSubscription?: () => void;
  onResumeSubscription?: () => void;
};
export default function UserCustomPlan({
  plan,
  isPricePage,
  isLanding,
  isPriceOverviewPage,
  workspaceSubscription,
  interval,
  isSummary,
  billingScheme,
  isWorkspaceSetting,
  isCurrentWorkspacePlan,
  onBillingSchemeChange,
  onPauseSubscription,
  onCancelSubscription,
  onResumeSubscription,
}: UserCustomPlanProps) {
  const { user: authUser } = useAuth();
  const router = useRouter();
  const userAbility = useUserAbility();
  const goToPage = () => {
    router.push('/upgrade');
  };

  const priceDescription = () => {
    router.push('/plan-overview');
  };

  const intervalPrice = useMemo(
    () =>
      plan.prices.find(
        (price) =>
          price.userId === authUser?.uid &&
          price.recurring?.interval === interval &&
          price.billingScheme === billingScheme
      ),
    [interval, billingScheme]
  );

  const priceSubscription = useMemo(
    () => workspaceSubscription?.plan.price.id === intervalPrice?.id,
    [workspaceSubscription, intervalPrice]
  );

  return (
    <PlanContainer
      priceSubscription={priceSubscription}
      isSummary={isSummary}
      isCurrentWorkspacePlan={isCurrentWorkspacePlan}
      isLanding={isLanding}
    >
      <div className="flex w-full flex-col gap-3">
        <PlanTitle
          name="Enterprise"
          isForYou={true}
          billingScheme={billingScheme}
          onBillingSchemeChange={onBillingSchemeChange}
          isPricePage={isPricePage}
          isLanding={isLanding}
          isCurrentWorkspacePlan={isCurrentWorkspacePlan}
        />
        {intervalPrice && (
          <PricesPreview
            price={intervalPrice}
            isPricePage={isPricePage}
            isLanding={isLanding}
            isCurrentWorkspacePlan={isCurrentWorkspacePlan}
          />
        )}
        <PlanDescription
          description="Our custom plan for you."
          isPricePage={isPricePage}
          isLanding={isLanding}
        />
        {!isSummary && (
          <Action
            type="custom_price"
            plan={plan}
            intervalPrice={intervalPrice}
            priceSubscription={priceSubscription}
            interval={interval}
            workspaceSubscription={workspaceSubscription}
            billingScheme={billingScheme}
          />
        )}
        {intervalPrice?.attributes && (
          <>
            <p
              className={clsx('font-medium', {
                'text-dark-lighter': isPricePage,
                'text-dark-700': isLanding,
                'text-dark-700 dark:text-dark-light':
                  !isPricePage && !isLanding,
              })}
            >
              Features you will have
            </p>
            <Attributes
              attributes={intervalPrice.attributes}
              isPricePage={isPricePage}
              isLanding={isLanding}
              isCurrentWorkspacePlan={isCurrentWorkspacePlan}
            />
          </>
        )}
      </div>
      <div className="flex w-full flex-col gap-3">
        {isWorkspaceSetting ? (
          <div className="flex w-full flex-col gap-6 py-3">
            {!isSummary && userAbility.can('upgrade', 'subscription') && (
              <Button variant="contained" fullWidth onClick={goToPage}>
                <span>Upgrade</span>
              </Button>
            )}
            <div className="flex w-full items-center justify-between gap-4">
              {workspaceSubscription?.canceledAt === null && (
                <>
                  {userAbility.can('stop', 'subscription') && (
                    <Button
                      variant="text"
                      size="small"
                      onClick={onCancelSubscription}
                    >
                      <span
                        className={clsx('font-normal', {
                          'text-dark-lighter': isCurrentWorkspacePlan,
                          'text-primary-light': !isCurrentWorkspacePlan,
                        })}
                      >
                        Cancel
                      </span>
                    </Button>
                  )}
                  {workspaceSubscription?.pauseCollection?.behavior ===
                  'keep_as_draft' ? (
                    <>
                      {userAbility.can('resume', 'subscription') && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onResumeSubscription}
                        >
                          <span
                            className={clsx('font-normal', {
                              'text-dark-lighter': isCurrentWorkspacePlan,
                              'text-primary-light': !isCurrentWorkspacePlan,
                            })}
                          >
                            Resume
                          </span>
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {userAbility.can('pause', 'subscription') && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onPauseSubscription}
                        >
                          <span
                            className={clsx('font-normal', {
                              'text-dark-lighter': isCurrentWorkspacePlan,
                              'text-primary-light': !isCurrentWorkspacePlan,
                            })}
                          >
                            Pause
                          </span>
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            {!isLanding && !isPriceOverviewPage && (
              <div className="flex w-full items-center justify-center">
                <Button onClick={priceDescription}>Detail description</Button>
              </div>
            )}
          </div>
        )}
      </div>
    </PlanContainer>
  );
}
