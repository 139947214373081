import { Button } from '@canvaza/serval-ui';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';

import type { ISubscription } from '@/shared/firebase';
import type { IPlan } from '@/shared/firebase/models/stripe-payment.interface';
import useUserAbility from '@/shared/hooks/useUserAbility';

import type { ISelectInterval } from '../..';
import Attributes from './attributes';
import Action from './components/Action';
import PlanContainer from './components/PlanContainer';
import PlanDescription from './components/PlanDescription';
import PlanTitle from './components/PlanTitle';
import PricePreview from './price-preview';

type StandardPlanProps = {
  plan: IPlan;
  interval: ISelectInterval;
  workspaceSubscription?: ISubscription;
  isSummary?: boolean;
  isWorkspaceSetting?: boolean;
  onCancelSubscription?: () => void;
  onPauseSubscription?: () => void;
  onResumeSubscription?: () => void;
  isCurrentWorkspacePlan?: boolean;
  isLanding?: boolean;
  isPricePage?: boolean;
  isPopular?: boolean;
  isPriceOverviewPage?: boolean;
};
export default function StandardPlan({
  plan,
  interval,
  workspaceSubscription,
  isSummary,
  isWorkspaceSetting,
  onCancelSubscription,
  onPauseSubscription,
  onResumeSubscription,
  isCurrentWorkspacePlan,
  isLanding,
  isPricePage,
  isPopular,
  isPriceOverviewPage,
}: StandardPlanProps) {
  const router = useRouter();
  const goToPage = () => {
    router.push('/upgrade');
  };

  const userAbility = useUserAbility();

  const priceDescription = () => {
    router.push('/plan-overview');
  };
  const intervalPrice = useMemo(
    () => plan.prices.find((price) => price.recurring?.interval === interval),
    [interval]
  );
  const priceSubscription = useMemo(
    () => workspaceSubscription?.plan.price.id === intervalPrice?.id,
    [workspaceSubscription, intervalPrice]
  );

  return (
    <PlanContainer
      priceSubscription={priceSubscription}
      isSummary={isSummary}
      isCurrentWorkspacePlan={isCurrentWorkspacePlan}
      isLanding={isLanding}
      isPricingPage={isPricePage}
      isPopular={isPopular}
    >
      <div className="flex h-4/5 w-full flex-col gap-3">
        <PlanTitle
          name={plan?.name}
          isPricePage={isPricePage}
          isCurrentWorkspacePlan={isCurrentWorkspacePlan}
          isLanding={isLanding}
        />

        {/* Price list  */}
        {intervalPrice && (
          <PricePreview
            price={intervalPrice}
            isPricePage={isPricePage}
            isLanding={isLanding}
            isCurrentWorkspacePlan={isCurrentWorkspacePlan}
          />
        )}

        {/* Description */}
        {plan.description && (
          <PlanDescription
            description={plan.description}
            isPricePage={isPricePage}
            isLanding={isLanding}
          />
        )}

        {/* Action Buttons */}
        {!isSummary && (
          <Action
            type="standard"
            workspaceSubscription={workspaceSubscription}
            priceSubscription={priceSubscription && !isLanding}
            intervalPrice={intervalPrice}
            interval={interval}
            plan={plan}
          />
        )}

        {/* Attributes */}
        <p
          className={clsx('font-medium', {
            'text-dark-lighter': isPricePage,
            'text-dark-700': isLanding,
            'text-dark-700 dark:text-dark-light': !isPricePage && !isLanding,
          })}
        >
          Features you will have
        </p>
        {
          <Attributes
            attributes={plan.attributes}
            isPricePage={isPricePage}
            isLanding={isLanding}
            isCurrentWorkspacePlan={isCurrentWorkspacePlan}
          />
        }
      </div>

      {/* Detail */}
      <div className="flex w-full flex-col justify-start gap-3">
        {isWorkspaceSetting ? (
          <div className="flex w-full flex-col gap-6 py-3">
            {!isSummary && userAbility.can('upgrade', 'subscription') && (
              <Button variant="contained" fullWidth onClick={goToPage}>
                <span>Upgrade</span>
              </Button>
            )}
            <div
              className={clsx(
                'flex w-full items-center justify-between gap-4',
                {
                  'pb-0': workspaceSubscription?.canceledAt === null,
                  'pb-9': workspaceSubscription?.canceledAt !== null,
                }
              )}
            >
              {workspaceSubscription?.canceledAt === null && (
                <>
                  {userAbility.can('stop', 'subscription') && (
                    <Button
                      variant="text"
                      size="small"
                      onClick={onCancelSubscription}
                    >
                      <span
                        className={clsx('font-normal', {
                          'text-dark-lighter': isCurrentWorkspacePlan,
                          'text-primary-light': !isCurrentWorkspacePlan,
                        })}
                      >
                        Cancel
                      </span>
                    </Button>
                  )}
                  {workspaceSubscription?.pauseCollection?.behavior ===
                  'keep_as_draft' ? (
                    <>
                      {userAbility.can('resume', 'subscription') && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onResumeSubscription}
                        >
                          <span
                            className={clsx('font-normal', {
                              'text-dark-lighter': isCurrentWorkspacePlan,
                              'text-primary-light': !isCurrentWorkspacePlan,
                            })}
                          >
                            Resume
                          </span>
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {userAbility.can('pause', 'subscription') && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onPauseSubscription}
                        >
                          <span
                            className={clsx('font-normal', {
                              'text-dark-lighter': isCurrentWorkspacePlan,
                              'text-primary-light': !isCurrentWorkspacePlan,
                            })}
                          >
                            Pause
                          </span>
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            {!isLanding && !isPriceOverviewPage && (
              <div className="flex w-full items-center justify-center">
                <Button onClick={priceDescription}>Detail description</Button>
              </div>
            )}
          </>
        )}
      </div>
    </PlanContainer>
  );
}
