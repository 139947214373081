/* eslint-disable no-param-reassign */
import clsx from 'clsx';

import { FEATURES } from '@/shared/config/feature-flag';
import type { IPlanAttributes } from '@/shared/firebase/models/stripe-payment.interface';

import Attribute from './Attribute';
import AttributeItem from './AttributeItem';
import Integration from './Integration';

type AttributesProps = {
  attributes: IPlanAttributes;
  isPricePage?: boolean;
  isLanding?: boolean;
  isCurrentWorkspacePlan?: boolean;
};
export default function Attributes({
  attributes,
  isPricePage,
  isLanding,
  isCurrentWorkspacePlan,
}: AttributesProps) {
  const enabledIntegrations = () =>
    Object.entries(attributes.integration)
      .filter(([_key, value]) => value)
      .reduce((prev, [key, value]) => {
        prev[key] = value;
        return prev;
      }, {} as { [key: string]: boolean });

  enabledIntegrations();
  return (
    <>
      {/* Video feature */}
      <Attribute
        title="Video limits"
        isPricePage={isPricePage}
        isCurrentWorkspacePlan={isCurrentWorkspacePlan}
      >
        <div className="flex flex-col gap-2">
          {/* number of videos */}
          <AttributeItem isCurrentWorkspacePlan={isCurrentWorkspacePlan}>
            <span
              className={clsx('text-sm', {
                'text-dark-light': isPricePage,
                'text-dark-700': isLanding,
                'text-dark-700 dark:text-dark-light':
                  !isPricePage && !isLanding,
              })}
            >
              {attributes.video.limit === 'inf'
                ? `Unlimited videos`
                : `${attributes.video.limit} ${
                    typeof attributes.video.limit === 'number' &&
                    attributes.video.limit > 0
                      ? 'videos'
                      : 'video'
                  } / workspace`}
            </span>
          </AttributeItem>
          {/* max video length in min */}
          <AttributeItem isCurrentWorkspacePlan={isCurrentWorkspacePlan}>
            <span
              className={clsx('text-sm', {
                'text-dark-light': isPricePage,
                'text-dark-700': isLanding,
                'text-dark-700 dark:text-dark-light':
                  !isPricePage && !isLanding,
              })}
            >
              {attributes.video.duration === 'inf'
                ? `Unlimited video recording length`
                : `${attributes.video.duration} min recording length`}
            </span>
          </AttributeItem>

          {/* Video quality */}
          <AttributeItem isCurrentWorkspacePlan={isCurrentWorkspacePlan}>
            <span
              className={clsx('text-sm', {
                'text-dark-light': isPricePage,
                'text-dark-700': isLanding,
                'text-dark-700 dark:text-dark-light':
                  !isPricePage && !isLanding,
              })}
            >
              {`Video quality - ${
                attributes.video.quality === '720p'
                  ? 'Up To 720p'
                  : 'High-definition up to 4k'
              }`}
            </span>
          </AttributeItem>

          {/* Video analytics */}
          <AttributeItem isCurrentWorkspacePlan={isCurrentWorkspacePlan}>
            <span
              className={clsx('text-sm', {
                'text-dark-light': isPricePage,
                'text-dark-700': isLanding,
                'text-dark-700 dark:text-dark-light':
                  !isPricePage && !isLanding,
              })}
            >
              {`Video Analytics - ${attributes.video.analytics}`}
            </span>
          </AttributeItem>
        </div>
      </Attribute>

      {/* General Features */}
      <Attribute
        title="Features"
        isCurrentWorkspacePlan={isCurrentWorkspacePlan}
      >
        <div className="flex flex-col items-start gap-2">
          {attributes.allowSSO && (
            <AttributeItem
              isCurrentWorkspacePlan={isCurrentWorkspacePlan}
              helperInfo="Once you have settled, members will be authenticated using SSO"
            >
              <span
                className={clsx('text-sm', {
                  'text-dark-light': isPricePage,
                  'text-dark-700': isLanding,
                  'text-dark-700 dark:text-dark-light':
                    !isPricePage && !isLanding,
                })}
              >
                {' '}
                SSO
              </span>
            </AttributeItem>
          )}

          {attributes.customizableBrand && (
            <AttributeItem
              isCurrentWorkspacePlan={isCurrentWorkspacePlan}
              helperInfo="You can customize your workspace appearance"
            >
              <span
                className={clsx('text-sm', {
                  'text-dark-light': isPricePage,
                  'text-dark-700': isLanding,
                  'text-dark-700 dark:text-dark-light':
                    !isPricePage && !isLanding,
                })}
              >
                Brand Customization
              </span>
            </AttributeItem>
          )}

          {attributes.allowSpeechToText && (
            <AttributeItem
              isCurrentWorkspacePlan={isCurrentWorkspacePlan}
              helperInfo="Subtitles will be automatically generated and used for SEO, then displayed."
            >
              <span
                className={clsx('text-sm', {
                  'text-dark-light': isPricePage,
                  'text-dark-700': isLanding,
                  'text-dark-700 dark:text-dark-light':
                    !isPricePage && !isLanding,
                })}
              >
                {' '}
                Video subtitle and SEO
              </span>
            </AttributeItem>
          )}
        </div>
      </Attribute>

      {/* Integrations feature */}
      {FEATURES.Attributes.integration && (
        <>
          {Object.values(enabledIntegrations()).length !== 0 ? (
            <Integration integration={enabledIntegrations()} />
          ) : (
            <Integration integration={enabledIntegrations()} />
          )}
        </>
      )}
    </>
  );
}
