import clsx from 'clsx';
import React from 'react';

import TieredPricePreview from '@/app/(protected)/(admin)/(plan)/plans/price/TieredPricePreview';
import { getCurrencySymbol } from '@/shared/config/currency.config';
import type { IPlanPrice } from '@/shared/firebase/models/stripe-payment.interface';
import { capitalizeFirstLetter } from '@/shared/utils/string';

type PricesPreviewProps = {
  price: IPlanPrice;
  isPricePage?: boolean;
  isLanding?: boolean;
  isCurrentWorkspacePlan?: boolean;
};
export default function PricesPreview({
  price,
  isPricePage,
  isLanding,
  isCurrentWorkspacePlan,
}: PricesPreviewProps) {
  return (
    <div className="w-full">
      <>
        {price.billingScheme === 'per_unit' && (
          <span
            className={clsx('flex items-end gap-1', {
              'text-dark-light': isPricePage || isCurrentWorkspacePlan,
              'text-dark-700 ': isLanding,
              'text-dark-700 dark:text-dark-light':
                !isPricePage && !isLanding && !isCurrentWorkspacePlan,
            })}
          >
            <span className="text-3xl font-bold">
              {getCurrencySymbol(price.currency)}
              {price.unitAmount}
            </span>
            <span
              className={clsx('text-xs md:text-sm', {
                'text-dark-light': isPricePage || isCurrentWorkspacePlan,
                'text-dark-700 ': isLanding,
                'text-dark-700 dark:text-dark-light':
                  !isPricePage && !isLanding && !isCurrentWorkspacePlan,
              })}
            >
              /{capitalizeFirstLetter(price.recurring?.interval ?? '')}{' '}
            </span>
            <span
              className={clsx('text-xs md:text-sm', {
                'text-dark-light': isPricePage || isCurrentWorkspacePlan,
                'text-dark-700 ': isLanding,
                'text-dark-700 dark:text-dark-light':
                  !isPricePage && !isLanding && !isCurrentWorkspacePlan,
              })}
            >
              /User
            </span>
          </span>
        )}

        {price.billingScheme === 'tiered' && price.tiers && (
          <div className="flex w-full flex-col gap-2">
            <span
              className={clsx('font-bold', {
                'text-dark-light': isPricePage || isCurrentWorkspacePlan,
                'text-dark-700 ': isLanding,
                'text-dark-700 dark:text-dark-light':
                  !isPricePage && !isLanding && !isCurrentWorkspacePlan,
              })}
            >
              Per {price.recurring?.interval}
            </span>
            <TieredPricePreview currency={price.currency} tiers={price.tiers} />
          </div>
        )}
      </>
    </div>
  );
}
