import clsx from 'clsx';

import { getCurrencySymbol } from '@/shared/config/currency.config';
import type { IPriceTier } from '@/shared/firebase/models/stripe-payment.interface';

type TieredPricePreviewProps = {
  tiers: IPriceTier[];
  currency: string;
};
export default function TieredPricePreview({
  tiers,
  currency,
}: TieredPricePreviewProps) {
  const getTierStart = (index: number) => {
    let start = 0;
    if (tiers) {
      const prvTier = tiers[index - 1];
      if (index !== 0 && prvTier && typeof prvTier.upTo === 'number') {
        start = prvTier.upTo + 1;
      }
    }

    return start;
  };

  return (
    <div className="flex w-full flex-col gap-0 rounded-md border border-dark-200/30 dark:border-dark-600">
      <div className="flex w-full items-center gap-0 font-semibold">
        <div className="flex w-full min-w-[5rem] items-center whitespace-nowrap px-2 py-1">
          Users
        </div>
        <div className="flex w-full min-w-[5rem] items-center whitespace-nowrap px-2 py-1">
          Amount
        </div>
      </div>

      {tiers?.map((tier, index) => {
        return (
          <div
            key={index}
            className={clsx(
              'flex w-full items-center border-b px-2 py-1',
              'border border-dark-200/30 dark:border-dark-600',
              {
                'border-b': index !== tiers!.length - 1,
              }
            )}
          >
            <div className={clsx('w-full min-w-[5rem] whitespace-nowrap')}>
              {`${getTierStart(index)}`} -{' '}
              {typeof tier.upTo === 'number' ? tier.upTo : <span>&infin;</span>}
            </div>
            <div className={clsx('w-full min-w-[5rem] whitespace-nowrap')}>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-2">
                  <span>Unit:</span>
                  <span>
                    {getCurrencySymbol(currency)}
                    {tier.unitAmount}
                  </span>
                </div>
                {tier.flatAmount > 0 && (
                  <div className="flex items-center gap-2">
                    <span>Flat:</span>
                    <span>
                      {getCurrencySymbol(currency)}
                      {tier.flatAmount}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
