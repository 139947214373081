import clsx from 'clsx';
import React from 'react';

type AttributeProps = {
  title: string;
  isPricePage?: boolean;
  isCurrentWorkspacePlan?: boolean;
  children: React.ReactNode;
};
export default function Attribute({
  title,
  isPricePage,
  isCurrentWorkspacePlan,
  children,
}: AttributeProps) {
  return (
    <div className="flex flex-col gap-3">
      <h1
        className={clsx('text-sm font-semibold', {
          'text-primary-light': isPricePage,
          'text-dark-lighter': isCurrentWorkspacePlan,
          'text-primary dark:text-primary-light':
            !isPricePage && !isCurrentWorkspacePlan,
        })}
      >
        {title}
      </h1>
      {children}
    </div>
  );
}
