import Attribute from './Attribute';
import AttributeItem from './AttributeItem';
import IntegrationIcons from './IntegrationIcons';

type IntegrationProps = {
  integration: { [key: string]: boolean };
};
export default function Integration({ integration }: IntegrationProps) {
  return (
    <Attribute title="Integrations">
      <div className="flex flex-wrap gap-2">
        <div className="grid w-full grid-cols-2 gap-2 xs:grid-cols-3 sm:grid-cols-2 md:grid-cols-3">
          <AttributeItem isIntegration>
            <IntegrationIcons
              name="Github"
              hasIntegration={integration.github}
            />
          </AttributeItem>

          <AttributeItem isIntegration>
            <IntegrationIcons name="Slack" hasIntegration={integration.slack} />
          </AttributeItem>

          <AttributeItem isIntegration>
            <IntegrationIcons name="Gmail" hasIntegration={integration.gmail} />
          </AttributeItem>

          <AttributeItem isIntegration>
            <IntegrationIcons name="Zoom" hasIntegration={integration.zoom} />
          </AttributeItem>

          <AttributeItem isIntegration>
            <IntegrationIcons
              name="Upwork"
              hasIntegration={integration.upwork}
            />
          </AttributeItem>

          <AttributeItem isIntegration>
            <IntegrationIcons
              name="Salesforce"
              hasIntegration={integration.salesforce}
            />
          </AttributeItem>
        </div>
      </div>
    </Attribute>
  );
}
